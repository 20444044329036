import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { imagesloaded } from 'imagesloaded';

// register GSAP plugins
gsap.registerPlugin(SplitText,ScrollTrigger,ScrollToPlugin,ScrollSmoother);

(function($) {
  // easy jQuery reference variables
  const $document = $(document);
  const $html = $('html');
  const $body = $('body');
  const $header = $('.header');
  let $homeHeroSwiper = false;

  // request animation frame
  var $flags = {
    scrolled: false,
    pauseScrolling: false,
    resized: false,
    resizedWidth: false,
    resizedHeight: false,
    pauseResizing: false,
  };

  var $timers = {
    scroll: -1,
    resize: -1,
    delta: 16.67 //  (1/60fps) / 1000ms = 16.67ms per frame
  };

  var $window = {
    width: window.innerWidth,
    lastWidth: window.innerWidth,
    height: window.innerHeight,
    lastHeight: window.innerHeight,
    scrollX: window.pageXOffset,
    lastScrollX: 0,
    scrollY: window.pageYOffset,
    lastScrollY: 0,
    scrollingDown: true
  };

  // prepare scroll function
  window.addEventListener('scroll', function() {
    $flags.scrolled = true;
    $window.scrollX = window.pageXOffset;
    $window.scrollY = window.pageYOffset;

    if($window.scrollY <= $window.lastScrollY) {
      $window.scrollingDown = false;
    } else {
      $window.scrollingDown = true;
    }
    $window.lastScrollY = $window.scrollY;

    if($timers.scroll !== -1) {
      clearTimeout($timers.scroll);
    }

    $timers.scroll = window.setTimeout(function() {
      $flags.scrolled = false;
    }, $timers.delta);
  });

  // prepare resize function
  window.addEventListener('resize', function() {
    $flags.resized = true;
    $window.width = window.innerWidth;
    $window.height = window.innerHeight;

    if($window.width !== $window.lastWidth) {
      $flags.resizedWidth = true;
    }
    $window.lastWidth = $window.width;

    if($window.height !== $window.lastHeight) {
      $flags.resizedHeight = true;
    }
    $window.lastHeight = $window.height;

    if($timers.resize !== -1) {
      clearTimeout($timers.resize);
    }

    $timers.resize = window.setTimeout(function() {
      $flags.resized = false;
      $flags.resizedWidth = false;
      $flags.resizedHeight = false;
    }, $timers.delta);
  });

  var requestScrollAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.scrolled) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resized) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeWidthAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedWidth) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeHeightAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedHeight) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  // browser detection
  var $ua = navigator.userAgent;

  if($ua.indexOf('Safari') !== -1 && $ua.indexOf('Chrome') === -1) {
    $html.addClass('safari');
  }

  if($ua.indexOf('Chrome') !== -1) {
    $html.addClass('chrome');
  }

  if($ua.match(/iP(hone|od|ad)/i) ) {
    $html.addClass('browser-ios');
  }

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test($ua)) {
    $html.addClass('browser-mobile');
  }

  // gsap smooth scrolling
  var $normalizeScroll = true;
  if($html.hasClass('safari')) {
    $normalizeScroll = false;
  }

  var $smoother = ScrollSmoother.create({
    smooth: 0.8,
    effects: true,
    normalizeScroll: $normalizeScroll
  });

  function updateTheme($theme, $bg) {
    $html.attr('data-theme', $theme);
    //$header[0].style.setProperty('--bg-color', $bg);
  }

  // projects
  $('.project').each(function() {
    var $project = $(this);
    var $theme = $project.data('theme');
    var $bg = $project.data('bg');

    ScrollTrigger.create({
      trigger: $project[0],
      start: "top top",
      end: "bottom top",
      onToggle: (self) => {
        if(self.isActive) {
          updateTheme($theme, $bg);
        } else {
          //updateTheme('dark', 'transparent');
        }
      }
    });

    // pin project headers
    ScrollTrigger.create({
      trigger: $project[0],
      start: "top top",
      end: "bottom top",
      pin: $project.find('.project-header')[0]
    });
  });

  // features
  $('.feature').each(function() {
    var $feature = $(this);
    var $theme = $feature.data('theme');
    var $bg = $feature.data('bg');
    var $end = "bottom top+=100%";
    if($feature.is(':last-child')) {
      $end = "bottom top";
    }

    ScrollTrigger.create({
      trigger: $feature[0],
      start: "top top-=100%",
      end: $end,
      onToggle: (self) => {
        if(self.isActive) {
          updateTheme($theme, $bg);
        } else {
          //updateTheme('dark', 'transparent');
        }
      }
    });

    // pin feature content
    if($feature.is(':last-child')) {
      $end = "bottom bottom";
    }
    ScrollTrigger.create({
      trigger: $feature[0],
      pin: $feature.find('.feature-content')[0],
      start: "top top",
      end: $end
    });
  });

  // project header
  $document.on('mouseover', '.header a, .project-header', function() {
    $('.project-header').addClass('hover');
  });
  $document.on('mouseleave', '.header a, .project-header', function() {
    $('.project-header').removeClass('hover');
  });

  // project archive menu
  $document.on('click', 'a', function(e) {
    var $link = $(this);

    if($link.attr('href') === '#projects') {
      e.preventDefault();

      if($html.hasClass('state--projects-menu-open')) {
        $html.removeClass('state--projects-menu-open');
      } else {
        $html.addClass('state--projects-menu-open');
      }
    } else {
      //$html.removeClass('state--projects-menu-open');
    }
  });

  // projects swiper
  if($('.project-archive_swiper').length > 0) {
    let $delay = ($('.project-archive_swiper')[0].hasAttribute('data-delay')) ? parseInt($('.project-archive_swiper').attr('data-delay')) : 5000;

    const $projectArchiveSwiper = new Swiper('.project-archive_swiper', {
      autoplay: {
        delay: $delay,
      },
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
    });

    $projectArchiveSwiper.on('slideChange', function() {
      $('.project-archive_menu a.active').removeClass('active');
      $('.project-archive_menu a').eq(this.realIndex).addClass('active');
    });

    $document.on('mouseover', '.project-archive_menu a', function() {
      var $index = $('.project-archive_menu a').index($(this));
      var $slide = $('.project-archive_swiper .swiper-slide[data-swiper-slide-index="'+$index+'"]').first();
      var $slideIndex = $('.project-archive_swiper .swiper-slide').index($slide);
      $projectArchiveSwiper.slideTo($slideIndex);
    });
  }

  // project links
  $document.on('click', 'a[data-project]', function(e) {
    var $project = $('.project[data-project="'+$(this).data('project')+'"]');
    if($project.length > 0) {
      e.preventDefault();

      $smoother.smooth(0);
      gsap.to(window, {
        duration: 0,
        scrollTo: $project[0],
        autoKill: true,
        onComplete: function() {
          setTimeout(function() {
            $html.removeClass('state--projects-menu-open');
            $smoother.smooth(0.8);
          }, 150);
        }
      });
    }
  });

  // info
  var $info = $('.home-info');
  var $infoTheme = $info.data('theme');
  var $infoBG = $info.data('bg');

  ScrollTrigger.create({
    trigger: $info[0],
    start: "top top-=100%",
    end: "bottom top",
    onToggle: (self) => {
      if(self.isActive) {
        updateTheme($infoTheme, $infoBG);
      } else {
        //updateTheme('dark', 'transparent');
      }
    }
  });

  // pin home info content
  ScrollTrigger.create({
    trigger: $info[0],
    pin: $info.find('.home-info-content')[0],
    start: "top top",
    end: "top top-=100%"
  });

  // adjust info height
  function adjustInfoHeight() {
    let $infoHeight = $('.home-info-content').outerHeight();
    $('.home-info').css({ height: ($infoHeight + $window.height) });
  }
  adjustInfoHeight();
  requestResizeAnimation(adjustInfoHeight);

  // info anchor link
  $document.on('click', 'a[href="/info"]', function(e) {
    if(goToAnchor('#info', 1, -$window.height)) {
      e.preventDefault();
    }
  });

  // home hero
  const $homeHero = $('.home-hero');
  if($homeHero.length > 0) {
    // pin hero
    ScrollTrigger.create({
      trigger: $homeHero[0],
      pin: $homeHero.find('.home-hero_swiper')[0],
      start: "top top",
      end: "bottom top+=100%"
    });

    let $delay = ($('.home-hero_swiper')[0].hasAttribute('data-delay')) ? parseInt($('.home-hero_swiper').attr('data-delay')) : 10000;

    $homeHeroSwiper = new Swiper('.home-hero_swiper', {
      autoplay: {
        delay: $delay,
        disableOnInteraction: true,
      },
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      navigation: {
        nextEl: '.home-hero_swiper .swiper-button-next',
        prevEl: '.home-hero_swiper .swiper-button-prev',
      },
      pagination: {
        el: '.home-hero_swiper .swiper-pagination',
        type: 'fraction',
        renderFraction: function(currentClass, totalClass) {
          return '<span class="'+currentClass+'"></span><span class="swiper-pagination-divider">/</span><span class="'+totalClass+'"></span>';
        },
      },
    });

    function updateSpeechBubble($text, $character) {
      $('.speech-bubble_text').html($text);

      var $split = new SplitText('.speech-bubble_text', { type:'chars,words' });
      gsap.fromTo($split.chars, {
        visibility: 'hidden'
      }, {
        duration: 1,
        visibility: 'visible',
        stagger: 0.03
      });

      $('.character--speech-bubble').attr('src', $character);
    }

    $homeHeroSwiper.on('slideChange', function() {
      const $slide = $(this.slides[this.activeIndex]);

      if(ScrollTrigger.isInViewport($homeHero[0]) && ($window.scrollY <= $window.height)) {
        const $theme = $slide.data('theme');
        const $bg = $slide.data('bg');
        updateTheme($theme, 'transparent');
      }

      if(!$homeHero.hasClass('state--speech-bubble-closed')) {
        const $text = $slide.data('text');
        const $character = $slide.data('character');

        updateSpeechBubble($text, $character);
      }
    });
    $homeHeroSwiper.autoplay.stop();

    // show speech bubble warning
    $document.on('mouseover', '.home-hero_swiper .swiper-button-close', function() {
      $('.home-hero').addClass('state--speech-bubble-warning');

      $homeHeroSwiper.autoplay.stop();
    });

    // hide speech bubble warning
    $document.on('mouseleave', '.home-hero_swiper .swiper-button-close', function() {
      $('.home-hero').removeClass('state--speech-bubble-warning');

      $homeHeroSwiper.autoplay.start();
    });

    // close speech bubble
    $document.on('click', '.home-hero_swiper .swiper-button-close', function(e) {
      e.preventDefault();

      $('.home-hero').removeClass('state--speech-bubble-warning');
      $('.home-hero').addClass('state--speech-bubble-closed');

      $homeHeroSwiper.autoplay.start();
    });

    // display speech bubble
    $document.on('click', '.character--closed, .speech-bubble_confirmation .cancel', function(e) {
      e.preventDefault();

      $('.home-hero').removeClass('state--speech-bubble-warning');
      $('.home-hero').removeClass('state--speech-bubble-closed');

      const $slide = $($homeHeroSwiper.slides[$homeHeroSwiper.activeIndex]);

      if(ScrollTrigger.isInViewport($homeHero[0])) {
        const $theme = $slide.data('theme');
        $html.attr('data-theme', $theme);
      }

      const $text = $slide.data('text');
      const $character = $slide.data('character');

      updateSpeechBubble($text, $character);

      $homeHeroSwiper.autoplay.stop();
      $homeHeroSwiper.autoplay.start();
    });

    // gallery
    $('.gallery-swiper').each(function() {
      const $gallery = $(this);
      let $delay = ($gallery[0].hasAttribute('data-delay')) ? parseInt($gallery.attr('data-delay')) : 5000;

      if($gallery.find('.swiper-slide').length > 1) {
        new Swiper($gallery[0], {
          autoplay: {
            delay: $delay,
            disableOnInteraction: true,
          },
          allowTouchMove: false,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          loop: true,
          navigation: {
            nextEl: $gallery.find('.swiper-button-next')[0],
          },
          pagination: {
            el: $gallery.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function(currentClass, totalClass) {
              return '<span class="'+currentClass+'"></span><span class="swiper-pagination-divider">/</span><span class="'+totalClass+'"></span>';
            },
          },
        });
      } else {
        $gallery.find('.swiper-button-next, .swiper-pagination').remove();
      }
    });
  }

  // anchor links
  function goToAnchor($anchor, $duration = 0.4, $offsetY = 0) {
    var $anchorTarget = $($anchor);

    if($anchorTarget.length) {
      var $y = $anchorTarget.offset().top;

      gsap.to(window, {
        duration: $duration,
        scrollTo: {
          y: $y,
          offsetY: $offsetY
        }
      });

      return true;
    } else {
      return false;
    }
  }

  if(window.location.hash) {
    setTimeout(function() {
      window.scrollTo(0, 0);
      goToAnchor(window.location.hash, 0);
    }, 500);
  }

  // page loaded
  $('.wrap').imagesLoaded()
  .always(function(imgLoad) {
    $body.addClass('loaded');

    if($homeHeroSwiper) {
      $homeHeroSwiper.slideTo(0);
      $homeHeroSwiper.autoplay.start();
    }
  });

})(jQuery); // Fully reference jQuery after this point.
